
import { Component, Vue } from "vue-property-decorator";
import { activityIsDossier } from "@/utils/model-helpers";
import { Getter } from "vuex-class";

@Component({
    components: {
        DossierTimeline: () => import("@/views/auth/dossier/tabs/timeline.vue"),
        DossierAppointments: () => import("@/views/auth/dossier/tabs/appointments.vue"),
        DossierTasks: () => import("@/views/auth/dossier/tabs/tasks.vue"),
        DossierDocuments: () => import("@/views/auth/dossier/tabs/documents.vue"),
        DossierContacts: () => import("@/views/auth/dossier/tabs/contacts.vue"),
        DossierPartners: () => import("@/views/auth/dossier/tabs/partners.vue"),
        DossierBids: () => import("@/views/auth/dossier/tabs/bids.vue"),
        GeneralNav: () => import("@/components/general/nav.vue"),
        GeneralNavLink: () => import("@/components/general/nav-link.vue"),
    },
})
export default class DossierPartMainPanel extends Vue {
    @Getter("activity/viewing") activity!: Activity;

    get activeTab() {
        return this.$route.query.tab;
    }

    get isDossier() {
        return activityIsDossier(this.activity);
    }
}
